<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <!-- Payouts List -->
        <div class="row clearfix">.
            <b-modal id="add_edit_modal" :title="modalTitle" hide-footer @shown="resetForm">
                <div class="modal-area">
                    <ValidationObserver v-slot="{ passes }">
                        <ul class="list-unstyled mb-0 payment-single-transfer">
                            <form id="funding_option" @submit.prevent="passes(addBenRelation)">
                                <li>  
                                    <ValidationProvider name="name" rules="required" v-slot="{ errors}">   
                                        <div class="form-group" :class="{'errors': errors.length && !formData.name || errors[0],'success':formData.name && !errors[0]} ">
                                            <span class="float-label">
                                                <input type="text" v-model="formData.name" class="form-control" name="name " id="name " placeholder="Name*">
                                                <label for="category_name " class="control-label">Relation Name*</label>
                                                <div class="line"></div>
                                                <i class="fa fa-times" v-if="errors.length && !formData.name || errors[0] "></i>
                                                <i class="fa fa-check" v-if="formData.name && !errors[0]"></i>
                                            </span>
                                        </div>
                                    </ValidationProvider>
                                    <!-- <span class="text-danger mb-3 d-block" v-if="apiErrorCode == 'E0100-302'">{{apiErrorMsg}}</span>  -->
                                </li>
                                <li>
                                    <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                        <b-button v-if="benRelid==''" type="submit" variant="primary btn-mw" size="lg">Add Relation </b-button>
                                        <b-button v-if="benRelid!=''" type="submit" variant="primary btn-mw" size="lg">Update Relation </b-button>
                                        <b-button @click="$bvModal.hide('add_edit_modal')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                    </div>
                                </li>
                            
                            </form>
                        </ul>
                    </ValidationObserver>
                </div>
            </b-modal>  
            <div class="col-lg-12">
                <div class="card card-top-line">
                    <!-- Header -->
                    <div class="header d-flex justify-content-between align-items-center">
                        <h2>Benificiary Relationship</h2>
                    </div>
                    <div class="body pt-0">
                        <div class="chart-top-action d-md-flex justify-content-end py-3">
                            <!-- SearchBox Filters -->
                            <div class="mr-0 mr-md-3 action-top-search">
                                <div class="input-group">
                                    <input type="text" class="form-control" v-model="searchText" placeholder="Search" aria-label="Search" aria-describedby="search-text"  v-on:keyup.enter="refreshTable">
                                    <div class="input-group-append" @click="refreshTable()">
                                        <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                    </div>
                                    <div class="input-group-append" @click="clearSearch()" v-if="this.searchText">
                                        <span class="input-group-text" id="search-mail"><i class="icon-close"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- Toggle Filter Button -->
                            <div class="fliter-main mr-auto mt-3 mt-md-0">
                            </div>

                            <div class="payment-dropdown d-flex flex-lg-row-reverse flex-column ml-lg-3 ml-0 mt-3 mt-lg-0">
                                <b-button size="lg" variant="primary btn-mw" @click="createForm()">Add New <i class="fa fa-plus-square"></i></b-button>
                                <!--  v-b-modal.payment_model -->
                                <!-- <button class="btn btn-outline-primary btn-lg mr-3">Download CSV <i class="fa fa-download ml-1"></i></button>
                                <button class="btn btn-outline-primary btn-lg mr-3">Upload <i class="fa fa-upload ml-1"></i></button> -->

                             </div>
                        </div>
                        <b-table 
                            responsive
                            ref="relationTable"
                            outlined
                            table-class="mb-0 table-custom"
                            head-variant="light"
                            :items="getRelationData" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection">
                            <template #head()="{label,  field: { key }}">
                                {{ label }}
                                <!-- Custom icons -->
                                <template>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </template>  
                            </template>
                            <template #cell(actions)="row">
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item @click="showEdit(row)">Edit</b-dropdown-item>
                                    <b-dropdown-item @click="deleteRelation(row.item.id)">Delete</b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
import '@/plugins/sweetalert2'
const LoaderComponent = require("@/components/admin/loader/LoaderComponent.vue").default;
import shared from "@/shared.js";
export default {
    name:'BenificiaryRelationComponent',
    components: {
        BreadCrumb : () => import('@/components/BreadCrumb.vue'),
        "main-loader": LoaderComponent,
        ValidationObserver,
        ValidationProvider,
    },
    methods: {
        async getRelationData() {
            try {
                this.$refs.loader.show();
                this.items = [];
                let paramData = {}
                if(this.searchText){
                    paramData.name = this.searchText
                }
                const response = await this.$http.get(this.$standapp + "customer/beneficiary/get/relationship/",{
                    params: paramData,
                    headers: {
                        Authorization: 'Basic YmRkMTc5NTY5MmQ1NTBiMWIwY2M0YmUzZmJkN2MyMTY6ZTczYTdhNDhjNDYzNDM5Nzg1MTUyNTI4MWE4NzdkMWY=',//"Bearer " + sessionStorage.getItem("jwtToken"),
                    },
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                
                if (response.data.code == "0085-000") {
                    response.data.data.map((item) => {
                        let lists = {
                            id:item.relationshipid,
                            name: item.name,
                            actions:'',
                        };
                        this.items.push(lists);
                    });
                    this.$refs.loader.hide();
                    return this.items;  
                }

            } catch (error) {
                console.log(error);
                this.$refs.loader.hide();
            }
        },
        
        refreshTable() {
            this.$refs.relationTable.refresh();
        },
        clearSearch(){
            this.searchText = '';
            this.$refs.relationTable.refresh();
        },

        resetForm(){
            if(this.benRelid== '') {
                this.formData.name = '';
                this.modalTitle = 'Add Benificiary Relation';
            } else {
                this.modalTitle = 'Edit Benificiary Relation';
            }
        },      

        createForm(){
            this.benRelid = '';
            this.$bvModal.show('add_edit_modal');
        },

        showEdit(element) {
            this.formData.name = element.item.name;
            this.benRelid = element.item.id;
            this.$bvModal.show('add_edit_modal');
        },

        addBenRelation(){
            if(this.benRelid!='') {
                this.updateRelation();   
            } else {
                this.$refs.loader.show();
                let addData = {
                    name: this.formData.name,
                };
                this.$http.post(this.$standapp + "customer/beneficiary/get/relationship/", addData,{
                    headers: {
                        'Authorization': 'Basic YmRkMTc5NTY5MmQ1NTBiMWIwY2M0YmUzZmJkN2MyMTY6ZTczYTdhNDhjNDYzNDM5Nzg1MTUyNTI4MWE4NzdkMWY=', //`Basic ${token}`
                    },
                }).then((res) => {
                    if(res.data.code == '0085-000'){
                        shared.toastrSuccess('Successfully Added');
                        this.refreshTable();
                        this.$bvModal.hide('add_edit_modal');
                        this.apiErrorMsg = '';
                        this.apiErrorCode = '';
                        this.$refs.loader.hide();
                    }
                }).catch((error) => {
                    // console.log(error);
                    this.apiErrorCode = error.response.data.code;
                    this.apiErrorMsg = error.response.data.message;
                    this.$refs.loader.hide();
                });
            }
        },

        updateRelation() {
            this.$refs.loader.show();
            let updateData = {
                relationshipid: this.benRelid,
                name: this.formData.name
            };
            this.$http.put(this.$standapp + "customer/beneficiary/get/relationship/", updateData,{
                headers: {
                    'Authorization': 'Basic YmRkMTc5NTY5MmQ1NTBiMWIwY2M0YmUzZmJkN2MyMTY6ZTczYTdhNDhjNDYzNDM5Nzg1MTUyNTI4MWE4NzdkMWY=' //`Basic ${token}`
                },
            }).then((res) => {
                if(res.data.code == '0085-000') {
                    shared.toastrSuccess('Successfully updated');
                    this.refreshTable();
                    this.$bvModal.hide('add_edit_modal');
                    this.$refs.loader.hide();
                }
            }).catch((error) => {
                this.apiErrorCode = error.response.data.code;
                this.apiErrorMsg = error.response.data.message;
                this.$refs.loader.hide();
            });             
        },

        deleteRelation(benRelid) {
            shared.deleteConfirm().then((result) => {
                if (result.isConfirmed) {
                    this.$refs.loader.show();
                    let deleteData = {
                        relationshipid: benRelid
                    };
                    this.$http.delete(this.$standapp + "customer/beneficiary/get/relationship/", {
                        headers: { 
                            'Authorization': 'Basic YmRkMTc5NTY5MmQ1NTBiMWIwY2M0YmUzZmJkN2MyMTY6ZTczYTdhNDhjNDYzNDM5Nzg1MTUyNTI4MWE4NzdkMWY='
                        },
                        data: deleteData
                    }).then((res) => {
                        if(res.data.code == '0085-000') {
                            shared.toastrSuccess('Successfully deleted');
                            this.refreshTable();
                            this.$refs.loader.hide();
                        }
                    }).catch((error) => {
                        if(error.response.data.code == 'E0085-000'){
                            this.apiErrorCode = error.response.data.code;
                            this.apiErrorMsg = error.response.data.detail;
                        } else {
                            this.apiErrorCode = error.response.data.code;
                            this.apiErrorMsg = error.response.data.message;
                        }
                        this.$refs.loader.hide();
                    }); 
                }
            })
        }, 
    },data(){
        return{
            isVisibleFilter: false,
            //items
            items:[],
           
            fields: [
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix' },
                // { key: 'status', label: 'Status' },
                { key: 'actions', label: 'Actions' },
                { sortable: true, sortByFormatted: true, filterByFormatted: true },
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 50,
            pageOptions: [50,100, 150, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            searchText:'',
            benRelid:'',
            modalTitle: '',
            formData:{
                name:'',
            },
            apiErrorCode:'',
            apiErrorMsg: ''
        }
    },mounted() {
        // this.totalRows = this.items.length
    }
}
</script>
